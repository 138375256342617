import * as React from 'react';
import { Navigate } from 'react-router-dom';
import usePublic from '../_usePublic';

import Brimstone from './brimstonehaunt.com'

export default function Home() {
    const { loadConfig } = usePublic();
    const [confReady, setConfReady] = React.useState(false);
    const [conf, setConf] = React.useState([]);
    const [content, setContent] = React.useState(null);

    let selectContent = (host, message) => {
        if(host === "brimstonehaunt") {
            // setContent(<Brimstone.Home message={message} />);
            setContent(<Brimstone.Xmas message={message} />);
        } else {
            setContent(<Navigate to="/employee" replace />);
        }
    }

    React.useEffect(function() {
        loadConfig((result) => {
            setConf(result);
            setConfReady(true);
        });
    }, []);

    React.useEffect(function() {
        if(!confReady) return;

        let host = window.location.hostname;
        let chunks = host.split(".");
        let tempHost = "";
        let tempMessage = "";
        
        conf.forEach((e) => {
            if((!chunks.includes("fm") && !chunks.includes("admin")) && chunks.includes(e.hostName) && e.enableHome) {
            // if(chunks.includes(e.hostName) && e.enableHome) {
                tempHost = e.hostName;
                tempMessage = e.publicMsg;
            }
        });
        selectContent(tempHost, tempMessage);
    }, [confReady]);

    return (<>{(confReady && content !== null) ? content : <>LOADING........</>}</>);
}