import * as React from 'react';
import {
    Row, Col, Form, FloatingLabel, Button
} from 'react-bootstrap';
import { useAlert } from '../../../_useAlert';
import useAuth from '../../../_useAuth';
import PhotoModal from '../photoModal';

export default function Festival() {
    const { alert } = useAlert();
    const { curFest, updateLoginFest } = useAuth();
    const [editFest, setEditFest] = React.useState({...curFest});
    const [showPhotoModal, setShowPhotoModal] = React.useState(false);

    function handleInput(e) {
        var key = e.target.getAttribute('name');
        var val = e.target.value;

        setEditFest({
            ...editFest,
            [key]: val
        });
    }

    function handleCheckBoxInput(e) {
        var key = e.target.getAttribute('name');
        var val = e.target.checked;

        setEditFest({
            ...editFest,
            [key]: val
        });
    }

    function saveFest(e) {
        e.preventDefault();

        fetch('/api/festivals/' + curFest.id, { 
            method: "PATCH" ,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "name": editFest.name,
                "welcomeMsg": editFest.welcomeMsg?.trim(),
                "publicMsg": editFest.publicMsg?.trim(),
                "enableHome": editFest.enableHome || false,
                "enableTodos": editFest.enableTodos || false,
                "enableApps": editFest.enableApps || false
            })
        }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return true;
                }
            }
        ).then(
            (success) => {
                alert("Success",
                    <Row>
                        <Col>
                            Festival has been updated.
                        </Col>
                    </Row>,
                    () => { updateLoginFest(); }
                );
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable to update festival. Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );
    }

    function saveLogo(imageData) {
        fetch('/api/festivals/' + curFest.id, { 
            method: "PATCH" ,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "logo": imageData || ""
            })
        }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return true;
                }
            }
        ).then(
            (success) => {
                updateLoginFest();
                closePhotoModal();
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable to update festival logo. Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );
    }

    function openPhotoModal() {
        setShowPhotoModal(true);
    }

    function closePhotoModal() {
        setShowPhotoModal(false);
    }

    return (<>
        <Row>
            <Col xs={12}>
                <Row className="my-3">
                    <Col>
                        <h1>Festival Settings</h1>
                    </Col>
                </Row>
                <Form onSubmit={saveFest}>
                    <Row>
                        <Col xs={12} lg={3}>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Check type="switch" id="enableHome" name="enableHome" label="Home Page" defaultChecked={editFest.enableHome} onChange={handleCheckBoxInput} />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Check type="switch" id="enableTodos" name="enableTodos" label="Todos" defaultChecked={editFest.enableTodos} onChange={handleCheckBoxInput} />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Check type="switch" id="enableApps" name="enableApps" label="Applications" defaultChecked={editFest.enableApps} onChange={handleCheckBoxInput} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} lg={9}>
                            <Row className="mb-3">
                                <Col xs={12} lg={6}>
                                    <div className="d-grid mb-4">
                                        <Button type="button" variant="outline-secondary" size="lg" onClick={openPhotoModal}>Change Logo</Button>
                                    </div>
                                    <FloatingLabel controlId="name" label="Name" className="mb-4">
                                        <Form.Control type="text" name="name" placeholder="Name" value={editFest.name} onChange={handleInput} />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <FloatingLabel controlId="welcomeMsg" label="Welcome Message">
                                        <Form.Control as="textarea" name="welcomeMsg" placeholder="Welcome Message" style={{ height: '150px' }} value={editFest.welcomeMsg} onChange={handleInput} /> 
                                    </FloatingLabel>
                                    <br/>
                                    <FloatingLabel controlId="publicMsg" label="Public Message">
                                        <Form.Control as="textarea" name="publicMsg" placeholder="Public Message" style={{ height: '150px' }} value={editFest.publicMsg} onChange={handleInput} /> 
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col sm={6} md={5} xl={4}>
                            <div className="d-grid">
                                <Button size="lg" type="submit">Save</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>

        <PhotoModal 
            show={showPhotoModal}
            close={closePhotoModal}
            savePhoto={saveLogo}
            aspect={4}
            newW={500}
            newH={125}
        />
    </>);
}