import './main.scss';

import CalendarVert from "./img/2024_calendar_vert.png";
import CalendarHorz from "./img/2024_calendar_horz.png";

import Logo from "./img/logos/brimstone-haunt-logo_xmas.png";
import XmasLogo from "./img/logos/scary_xmas_logo.png";
import BogLogo from "./img/logos/the_bog_logo_xmas.png";
import PsychosisLogo from "./img/logos/psychosis_xmas.png";
import PaintLogo from "./img/logos/paintball_xmas.png";
import ZombieLogo from "./img/logos/zombie_assault_xmas.png";
import CrushLogo from "./img/logos/cranium_crusher_xmas.png";
import AxeLogo from "./img/logos/axe_throwing_xmas.png";
import Yuletide from "./img/sponsors/yuletide-logo.png";
import CelticFest from "./img/sponsors/celticfest_logo.png";
import BBQ from "./img/sponsors/bbq_logo.png";
import MtnDew from "./img/sponsors/mtn_dew_logo.png";
import MillerLite from "./img/sponsors/miller_lite.png";
import Woodchuck from "./img/sponsors/woodchuck.png";
import Sonder from "./img/sponsors/Sonder_white_png.png";
import NowHiring from "./img/now-hiring.png";

import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Container, Row, Col, Button, Navbar, Nav, NavDropdown, Alert
} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import $ from 'jquery';

var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
	},
	SmallWindow: function() {
		return window.innerWidth < 768;
	},
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

export default function Xmas(props) {
    const [showAlert, setShowAlert] = React.useState(true);

    React.useEffect(function() {
        if(!isMobile.any()) {
            var tag = document.createElement('script');
            tag.id = 'iframe-demo';
            tag.src = 'https://www.youtube.com/iframe_api';
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }

        $(document).on("click", ".list-group-item-action", function() {
            $($(this).data("target")).slideToggle();
            $(this).toggleClass("active");
        }).on("scroll", function() {
            $("#main-layout").toggleClass("navbar-short", $(window).scrollTop() > 5);
        });

        return function cleanup() {
            
        };
    }, []);

    function scrollPageTo(target) {
        if($(window).scrollTop() <= 5) {
            $(window).scrollTop(6);
            setTimeout(function() {
                $('html, body').animate({
                    scrollTop: $(target).offset().top
                }, 250);
            }, 100);
        } else {
            $('html, body').animate({
                scrollTop: $(target).offset().top
            }, 250);
        }
    }

    return (<>
        <Helmet>
             {/* {{!------------------------------------------------ ROLLER SCRIPT ---------------------------------------------------}} */}
            <script 
                id="roller-checkout" 
                type="text/javascript" 
                src="https://cdn.rollerdigital.com/scripts/widget/checkout_iframe.js" 
                data-checkout="https://ecom.roller.app/brimstonehaunt/tickets/en-us/home"
            >
            </script>
            {/* {{!---------------------------------------------- END ROLLER SCRIPT -------------------------------------------------}} */}
            
            {/* {{!----------------------------------------- Attentive tag live --------------------------------------------}} */}
            <script type="text/javascript" src="https://cdn.attn.tv/brimstonehaunt/dtag.js"></script>
            {/* {{!--------------------------------------- END Attentive tag live ------------------------------------------}} */}
        </Helmet>

        {/* {{!----------------------------------------- Q1 MEDIA RETARGETTING PIXEL --------------------------------------------}} */}
        <img height="1" width="1" style={{"borderStyle":"none", "position": "absolute", "top": "-100px", "left": "-100px"}} alt="retargetting pixel" src="https://insight.adsrvr.org/track/pxl/?adv=briyt8x&ct=0:pyvkipz&fmt=3"/>
        {/* {{!--------------------------------------- END Q1 MEDIA RETARGETTING PIXEL ------------------------------------------}} */}

        {/* {{!--------------------------------------- MESSAGES ------------------------------------------}} */}
        {props.message && showAlert &&
            <Alert variant="warning">
                <h4>NOTICE</h4>
                <br/>
                {props.message}
                <br/><br/>
                <Button variant="dark" size="sm" onClick={() => setShowAlert(false)}>DISMISS</Button>
            </Alert>
        }
        {/* {{!--------------------------------------- END MESSAGES ------------------------------------------}} */}

        {/* {{!--------------------------------------- HEADER ------------------------------------------}} */}
        <Navbar collapseOnSelect  variant="dark" expand="md" id="main-nav" className="fixed-top py-0 px-3">
            <Navbar.Brand href="/"><img alt ="Logo" src={Logo} /></Navbar.Brand>

            <Navbar.Toggle aria-controls="main-nav-menu" />

            <Navbar.Collapse id="main-nav-menu" className="flex-md-column">
                <Nav id="navbar-social-links" className="ms-auto justify-content-center">
                    <li className="nav-item ms-2">
                        <a href="http://www.facebook.com/brimstonehaunt" target="_blank">
                            <FontAwesomeIcon icon="fa-brands fa-facebook-f" className="fa fa-icon-small bg-transparent"></FontAwesomeIcon>
                        </a>
                    </li>
                    <li className="nav-item ms-2">
                        <a href="http://www.instagram.com/brimstonehaunt" target="_blank">
                            <FontAwesomeIcon icon="fa-brands fa-instagram" className="fa fa-icon-small bg-transparent"></FontAwesomeIcon>
                        </a>
                    </li>
                </Nav>
                <Nav className="ms-auto my-5 my-md-auto">
                    <Nav.Link className="d-inline-block d-md-none d-xl-inline-block" onClick={() => {scrollPageTo('#bog')}}  href='##'>The BOG</Nav.Link>
                    <Nav.Link className="d-inline-block d-md-none d-xl-inline-block" onClick={() => {scrollPageTo('#psychosis')}}  href='##'>Psychosis</Nav.Link>
                    <Nav.Link className="d-inline-block d-md-none d-xl-inline-block" onClick={() => {scrollPageTo('#midway')}}  href='##'>Midway</Nav.Link>
                    <NavDropdown title="Attractions" id="attractionDropdown" className="d-none d-md-inline-block d-xl-none">
                        <NavDropdown.Item onClick={() => {scrollPageTo('#bog')}}>The BOG</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => {scrollPageTo('#psychosis')}}>Psychosis</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => {scrollPageTo('#midway')}}>Midway</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link onClick={() => {scrollPageTo('#tickets')}} href='##'>Tickets</Nav.Link>
                    <Nav.Link onClick={() => {scrollPageTo('#schedule')}} href='##'>Schedule</Nav.Link>
                    <Nav.Link onClick={() => {scrollPageTo('#directions')}} href='##'>Directions</Nav.Link>
                    <Nav.Link onClick={() => {scrollPageTo('#faq')}} href='##'>FAQ</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        {/* {{!--------------------------------------- END HEADER ------------------------------------------}} */}

        <Container fluid>
        {/* {{!----------------------------------------------------- HERO -------------------------------------------------------}} */}
            <div id="bg-video-player"></div>
            <div className="section full-height video-bg">
                <div className="section-content">
                    <Row className="h-100">
                        <Col lg={6} className="my-auto">
                            <Row className="justify-content-center">
                                <Col xs="auto">
                                    <Row>
                                        <Col xs="auto" className="h-font-lg text-uppercase">
                                            Jingle Bells. Jingle Bells.
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="auto" className="h-font-lg text-uppercase">
                                            'Tis the Season for Yells.
                                        </Col>
                                    </Row>
                                    <Row className="mt-5">
                                        <Col xs="auto" className="h-font-md text-uppercase">
                                            Enjoy these Merry Scares this holiday!
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} className="my-auto text-center">
                            <img src={XmasLogo} style={{"maxHeight": "none", "maxWidth": "none", "width": "350px"}} /><br /><br />
                            <Button className="btn-lg rounded-pill py-md-3 px-5 text-white" onClick={() => { scrollPageTo('#tickets'); }}>
                                <FontAwesomeIcon icon="fa-solid fa-ticket" className="fa fa-icon-normal me-2 align-bottom" /> Tickets
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        {/* {{!--------------------------------------------------- END HERO -----------------------------------------------------}} */}

        {/* {{!------------------------------------------ The Bog ATTRACTION -------------------------------------------}} */}
            <div id="bog" className="anchor"></div>
            <div className="parallax section full-height" data-background='bg_bog' data-stellar-background-ratio="0.25">
                <div className="section-content">
                    <div className="row h-100">
                        <div className="col my-auto">
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <img src={BogLogo} alt="The BOG Attraction Logo" width="300" />
                                </div>
                            </div>
                            <div className="row justify-content-center mt-5">
                                <div className="col-12 col-lg-9 col-xl-6 text-justify">
                                    <p>Experience the rebirth of THE BOG, a petrifying legend brought back to life from the Springboro Haunted Hayride and Black Bog. In the shadowy town of Brimstone, a monstrous presence lurks, born from the depths of legends and fear. Beware the vile tendrils of black vines, slithering and seeking, as they spread their darkness. This season, Brimstone Haunt goes back to its roots and renames the Forgotten Forest to THE BOG! Will you emerge from the shadows, heart racing and breathless, to tell the tale of your encounter with THE BOG?</p>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col">
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <h5>ATTRACTION INTENSITY</h5>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-auto text-danger">
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1" />
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1" />
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1" />
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1" />
                                            <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-large mx-1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!---------------------------------------- END THE BOG ATTRACTION -----------------------------------------}} */}

        {/* {{!--------------------------------------------- PSYCHOSIS ATTRACTION -----------------------------------------------}} */}
            <div id="psychosis" className="anchor"></div>
            <div className="parallax section full-height" data-background='bg_psychosis' data-stellar-background-ratio="0.25">
                <div className="section-content">
                    <div className="row h-100">
                        <div className="col my-auto">
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <img src={PsychosisLogo} alt="Psychosis Attraction Logo" width="300" />
                                </div>
                            </div>
                            <div className="row justify-content-center mt-5">
                                <div className="col-12 col-lg-9 col-xl-6 text-justify">
                                    <p>Around every dark corner awaits disturbing scenes of horror that will pull you further from reality. Get the yell scared out of you as you wind through the dark maze-like pathways trying to find a way out. Or surrender to the darkness and join the horror in a permanent state of Psychosis.</p>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col">
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <h5>ATTRACTION INTENSITY</h5>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-auto text-danger">
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1"></FontAwesomeIcon>
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1"></FontAwesomeIcon>
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1"></FontAwesomeIcon>
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1"></FontAwesomeIcon>
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1"></FontAwesomeIcon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!------------------------------------------- END PSYCHOSIS ATTRACTION ---------------------------------------------}} */}

        {/* {{!---------------------------------------------- MIDWAY ATTRACTION -------------------------------------------------}} */}
            <div id="midway" className="anchor"></div>
            <div className="parallax section full-height-lg" data-background='midway' data-stellar-background-ratio="0.25">
                <div className="section-content">
                    <div className="row h-lg-100">
                        <div className="col">
                            <div className="row my-5">
                                <div className="col-12 col-lg-6">
                                    <div className="row justify-content-center">
                                        <div className="col-auto ">
                                            <img src={ZombieLogo} alt="Zombie Assault Attraction Logo" width="125" />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-4">
                                        <div className="col-12 col-lg-9 text-justify">
                                            <p>The zombie apocalypse is here. The infection has spread to every corner of the globe and the future seems bleak. There is a small refugee camp nearby that has managed to fight off the hoard and is heavily reinforced. Board our armored bus with a group of fellow survivors and a small assault team who will protect you in transit to the camp and see if you can survive the zombie assault.</p>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col">
                                            <div className="row justify-content-center">
                                                <div className="col-auto">
                                                    <h6>EXPERIENCE INTENSITY</h6>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-auto text-danger">
                                                    <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="row justify-content-center">
                                        <div className="col-auto ">
                                            <img src={PaintLogo} alt="Dead Shot Paintball Attraction Logo" width="250" />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-4">
                                        <div className="col-12 col-lg-9 text-justify">
                                            <p>Take up arms against the zombie hoard. Test out your accuracy and reflexes against our army of zombies in this shooting gallery. Hurl paintballs at zombies as they dodge this way and that avoiding their colorful doom. Can you prevent an apocalypse or just paint a pretty picture?</p>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col">
                                            <div className="row justify-content-center">
                                                <div className="col-auto">
                                                    <h6>EXPERIENCE INTENSITY</h6>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-auto text-danger">
                                                    <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-5">
                                <div className="col-12 col-lg-6">
                                    <div className="row justify-content-center">
                                        <div className="col-auto ">
                                            <img src={CrushLogo} alt="Cranium Crusher Attraction Logo" width="300" />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-4">
                                        <div className="col-12 col-lg-9 text-justify">
                                            <p>Can you reunite the zombie with its brain? Keep that hammer swinging and don't miss. You'll need to crush that brain back into the zombie's cranium before time runs out!</p>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col">
                                            <div className="row justify-content-center">
                                                <div className="col-auto">
                                                    <h6>EXPERIENCE INTENSITY</h6>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-auto text-danger">
                                                    <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="row justify-content-center">
                                        <div className="col-auto ">
                                            <img src={AxeLogo} alt="Axe Throwing Attraction Logo" width="150" />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-4">
                                        <div className="col-12 col-lg-9 text-justify">
                                            <p>How good is your axe throw? Step on up and find out!</p>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col">
                                            <div className="row justify-content-center">
                                                <div className="col-auto">
                                                    <h6>EXPERIENCE INTENSITY</h6>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-auto text-danger">
                                                    <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!---------------------------------------- END MIDWAY ATTRACTION -------------------------------------------}} */}

        {/* {{!--------------------------------------------------- TICKETS ------------------------------------------------------}} */}
            <div id="tickets" className="anchor"></div>
            <div className="parallax section dividers-red" data-background='bg_stone_red' data-stellar-background-ratio="0.25">
                <div className="section-heading">
                    <h2>
                        TICKETS
                    </h2>
                </div>
                <div className="section-content mt-5">
                    <div className="row my-5">
                        <div className="col">
                            <span className="d-block text-center">
                                <button onClick={() => window.RollerCheckout.show()} className="btn btn-primary btn-lg py-md-3 px-md-4 text-white">
                                    <FontAwesomeIcon icon="fa-solid fa-ticket" className="fa fa-icon-normal me-3 align-bottom" />
                                    <span>Purchase</span>
                                    <FontAwesomeIcon icon="fa-solid fa-ticket" className="fa fa-icon-normal ms-3 align-bottom" />
                                    <img height="1" width="1" style={{"borderStyle": "none"}} alt="" src="https://insight.adsrvr.org/track/pxl/?adv=briyt8x&ct=0:8uj97lp&fmt=3" />
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col">
                            <span className="d-block text-center h6">
                                Purchase online to save some time. Base ticket prices are cheaper online and you can skip the ticket office line.
                            </span>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                        {/* {{!-- Ultimate --}} */}
                        <div className="col-10 col-lg-5 col-xl-3 my-3">
                            <div className="ticket-card">
                                <div className="row px-4">
                                    <div className="col">
                                        <h4>ULTIMATE SCARY LITTLE CHRISTMAS</h4>
                                        <button onClick={() => window.RollerCheckout.show({productId: "1101990"})} className="btn btn-primary btn-sm text-white">Purchase</button>
                                        <div className="divider"></div>
                                    </div>
                                </div>
                                <div className="row px-4">
                                    <div className="col">
                                        <h5 className="pt-0">Online Price:</h5>
                                        <span className="price">$42.99</span>
                                        <br/><br/>
                                        <span>Good for unlimited entry to both main attractions PLUS unlimited access to all midway experiences!</span>
                                        <div className="divider"></div>
                                    </div>
                                </div>
                                <div className="row px-4">
                                    <div className="col">
                                        <h4>FAST PASS ULTIMATE</h4>
                                        <button onClick={() => window.RollerCheckout.show({productId: "1101990"})} className="btn btn-primary btn-sm text-white">Purchase</button>
                                        <div className="divider"></div>
                                    </div>
                                </div>
                                <div className="row px-4">
                                    <div className="col">
                                        <h5 className="pt-0">Online Price:</h5>
                                        <span className="price">$49.99</span>
                                        <br/><br/>
                                        <span>Skip the line with FAST PASS unlimited entry to both main attractions PLUS unlimited access to all midway experiences!</span>
                                        <div className="divider"></div>
                                    </div>
                                </div>
                                <div className="row px-4">
                                    <div className="col">
                                        <span>Bog + Psychosis + Zombie Assault + Dead Shot + Cranium Crusher + Axe Throwing</span>
                                        <div className="divider"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {{!-- Unlimited --}} */}
                        <div className="col-10 col-lg-5 col-xl-3  my-3">
                            <div className="ticket-card">
                                <div className="row px-4">
                                    <div className="col">
                                        <h4>SCARY LITTLE CHRISTMAS</h4>
                                        <button onClick={() => window.RollerCheckout.show({productId: "1101985"})} className="btn btn-primary btn-sm text-white">Purchase</button>
                                        <div className="divider"></div>
                                    </div>
                                </div>
                                <div className="row px-4">
                                    <div className="col">
                                        <h5 className="pt-0">Online Price:</h5>
                                        <span className="price">$28.99</span>
                                        <br/><br/>
                                        <span>Good for unlimited entry to both main attractions</span>
                                        <div className="divider"></div>
                                    </div>
                                </div>
                                <div className="row px-4">
                                    <div className="col">
                                        <h4>FAST PASS</h4>
                                        <button onClick={() => window.RollerCheckout.show({productId: "1101985"})} className="btn btn-primary btn-sm text-white">Purchase</button>
                                        <div className="divider"></div>
                                    </div>
                                </div>
                                <div className="row px-4">
                                    <div className="col">
                                        <h5 className="pt-0">Online Price:</h5>
                                        <span className="price">$44.99</span>
                                        <br/><br/>
                                        <span>Skip the line with FAST PASS unlimited entry to both main attractions!</span>
                                        <div className="divider"></div>
                                    </div>
                                </div>
                                <div className="row px-4">
                                    <div className="col">
                                        <span>Bog + Psychosis</span>
                                        <div className="divider"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row my-5">
                        <div className="col">
                            <span className="d-block text-center h4">
                                <img src={Yuletide} alt="" width="250px" /><br/><br/>
                                <a href="https://yuletidevillage.com/" target="_blank" className="btn btn-dark text-white">
                                    <FontAwesomeIcon size="lg" icon="fa-solid fa-ticket" className="me-3" />
                                    Yuletide Village + Scary Little Christmas Combo
                                    <FontAwesomeIcon size="lg" icon="fa-solid fa-ticket" className="ms-3" />
                                    <img height="1" width="1" style={{"borderStyle": "none"}} alt="" src="https://insight.adsrvr.org/track/pxl/?adv=briyt8x&ct=0:8uj97lp&fmt=3" />
                                </a>
                            </span>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                        <div className="col-md-4 col-lg-3 col-xl-2 fine-print text-center text-nowrap">
                            <span>Absolutely no refunds</span>
                        </div>
                        <div className="col-8 col-md-4 col-lg-3 col-xl-2 fine-print text-center text-nowrap divider-right divider-left">
                            <a href="/policies#policy4" className="font-weight-bold">Disclaimer</a>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-2 fine-print text-center text-nowrap">
                            <span>Prices are subject to change.</span>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!------------------------------------------------- END TICKETS ----------------------------------------------------}} */}

        {/* {{!-------------------------------------------------- SCHEDULE ------------------------------------------------------}} */}
            <div id="schedule" className="anchor"></div>
            <div className="section">
                <div className="row no-gutters">
                    <div className="col-lg-6 pr-lg-5">
                        <div className="section-heading">
                            <h2 className="text-lg-right">
                                Schedule
                            </h2>
                        </div>
                        <div className="section-content mt-5">
                            <div className="row justify-content-end text-lg-right">
                                <div className="col-12 col-xl-8">
                                    <ul>
                                        <li className="mb-5">
                                            Open weekend nights through the fall. Attractions will begin running when it is fully dark out.
                                        </li>
                                        <li>
                                            Opening and closing times vary by day. Check the calendar and/or our facebook for the most up to date info.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 d-none d-lg-block">
                        <img src={CalendarHorz} alt="Season Calendar" />
                    </div>
                    <div className="col-lg-6 d-lg-none">
                        <img src={CalendarVert} alt="Season Calendar" />
                    </div>
                </div>
            </div>
        {/* {{!------------------------------------------------- END SCHEDULE ---------------------------------------------------}} */}

        {/* {{!------------------------------------------------- DIRECTIONS -----------------------------------------------------}} */}
            <div id="directions" className="anchor"></div>
            <div className="section">
                <div className="row no-gutters">
                    <div className="col-lg-6 order-2 order-lg-1">
                        <iframe id="embeded-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3078.466591997571!2d-83.98506478424625!3d39.503957679481616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x884094d73418c903%3A0x22bede9365cedce0!2s525+Brimstone+Rd%2C+Wilmington%2C+OH+45177!5e0!3m2!1sen!2sus!4v1492628414617" frameBorder="0" allowFullScreen>
                            Your web browser does not support iframes. Please update to a browser with iframe support. We recommend <a href="https://www.google.com/chrome/">Google Chrome</a>
                        </iframe>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 pl-lg-5">
                        <div className="section-heading">
                            <h2 className="text-lg-left">
                                DIRECTIONS
                            </h2>
                        </div>
                        <div className="section-content mt-5">
                            <div className="row">
                                <div className="col-12 col-xl-8">
                                    <ul>
                                        <li className="mb-5">
                                            <strong>Coming from I-75: </strong>Take Exit 38 (Springboro/Franklin) and head East on State Route 73 for 15 miles then turn left on Brimstone Road.
                                        </li>
                                        <li>
                                            <strong>Coming from I-71: </strong>Take Exit 45 (Waynesville) and head West on State Route 73 for 2 miles then turn right on Brimstone Road.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col">
                                    <a href="http://maps.apple.com/?address=525,Brimstone+Rd,Wilmington,Ohio" target="_blank">
                                        <FontAwesomeIcon icon="fa-solid fa-map-pin" className="fa fa-icon-small"></FontAwesomeIcon> 525 Brimstone Rd, Wilmington, OH 45177
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!------------------------------------------------ END DIRECTIONS --------------------------------------------------}} */}

        {/* {{!----------------------------------------------------- FAQ --------------------------------------------------------}} */}
            <div id="faq" className="anchor"></div>
            <div className="parallax section" data-background='bg_stone_gray' data-stellar-background-ratio="0.25">
                <div className="section-heading">
                    <h2>
                        F A Q
                    </h2>
                </div>
                <div className="section-content mt-5">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 col-xl-6">
                            <div className="faq list-group">
                                {/* {{!-- FAQ 1 --}} */}
                                <div className="list-group-item list-group-item-action" data-target="#faq1">
                                    <span>What time do you close? </span>
                                </div>
                                <div id="faq1" className="list-group-item collapse">
                                    <span>Our closing hours may vary each night. Please check the calendar and/or our Facebook for the most up-to-date information</span>
                                </div>
                                {/* {{!-- FAQ 2 --}} */}
                                <div className="list-group-item list-group-item-action" data-target="#faq2">
                                    <span>Will I be able to experience all the attractions in one night?</span>
                                </div>
                                <div id="faq2" className="list-group-item collapse">
                                    <span>Our queue lines close according to the schedule on the calendar. If you're in line for an attraction when the park closes, you will still be able to complete that attraction.</span>
                                    <br/>
                                    <span style={{textAlign: "left", textAlignLast: "left"}}>Here are a few tips to help you make the most of your visit:</span>
                                    <ol style={{marginLeft: "50px", textAlign: "left", textAlignLast: "left"}}>
                                        <li>Arrive early—attractions open at dark.</li>
                                        <li>Start with the shortest queue lines.</li>
                                        <li>Purchase tickets online to avoid waiting in line at the ticket office.</li>
                                        <li>Experience each attraction once before returning for a second visit.</li>
                                    </ol>
                                </div>
                                {/* {{!-- FAQ 3 --}} */}
                                <div className="list-group-item list-group-item-action" data-target="#faq3">
                                    <span>Are your attractions accessible for individuals with disabilities?</span>
                                </div>
                                <div id="faq3" className="list-group-item collapse">
                                    <span>Yes, our Midway is accessible. However, please be aware that our parking lot is grass, and much of the Midway is comprised of grass and gravel. For special accommodations, please contact us at contact@brinstonehaunt.com.</span>
                                </div>
                                {/* {{!-- FAQ 8 --}} */}
                                <div className="list-group-item list-group-item-action" data-target="#faq8">
                                    <span>Will the actors touch us?</span>
                                </div>
                                <div id="faq8" className="list-group-item collapse">
                                    <span>No, our actors do not intentionally touch patrons. However, in tight spaces and low lighting, accidental bumps may occur. We also ask that patrons refrain from touching our employees.</span>
                                </div>
                                {/* {{!-- FAQ 4 --}} */}
                                <div className="list-group-item list-group-item-action" data-target="#faq4">
                                    <span>Do you accept credit cards?</span>
                                </div>
                                <div id="faq4" className="list-group-item collapse">
                                    <span>Yes, we accept all major credit cards.</span>
                                </div>
                                {/* {{!-- FAQ 5 --}} */}
                                <div className="list-group-item list-group-item-action" data-target="#faq5">
                                    <span>Are there age restrictions?</span>
                                </div>
                                <div id="faq5" className="list-group-item collapse">
                                    <span>No, we don't impose age restrictions. We leave that decision to the guardian. We’ve seen everything from young kids leaving with laughter to adults leaving in tears.</span>
                                </div>
                                {/* {{!-- FAQ 6 --}} */}
                                {/* <div className="list-group-item list-group-item-action" data-target="#faq6">
                                    <span>Do you close for weather?</span>
                                </div>
                                <div id="faq6" className="list-group-item collapse">
                                    <span>Yes if the weather is bad there is a chance we will have to close since our attractions are outdoors. You can check our social media for announcements related to closings.</span>
                                </div> */}
                                {/* {{!-- FAQ 7 --}} */}
                                {/* {{!-- <div className="list-group-item list-group-item-action" data-target="#faq7">
                                    <span>Is my ticket only good the night I purchase it?</span>
                                </div>
                                <div id="faq7" className="list-group-item collapse">
                                    <span>No. Tickets are good for any night during the season in which they were purchased.</span>
                                </div> --}} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!--------------------------------------------------- END FAQ ------------------------------------------------------}} */}

        {/* {{!--------------------------------------------------- SPONSORS -----------------------------------------------------}} */}
            <div className="parallax section" data-background='bg_scratchy_gray' data-stellar-background-ratio="0.25">
                <div className="section-heading">
                    <h2>
                        PARTNERS
                    </h2>
                </div>
                <div className="section-content mt-5 text-center">
                    <div className="row justify-content-center">
                        <div className="col-4 col-lg-3 col-xl-2 my-auto">
                            <a href="http://www.yuletidevillage.com" target="_blank"><img src={Yuletide} /></a>
                        </div>
                        <div className="col-4 col-lg-3 col-xl-2 my-auto">
                            <a href="http://www.celticfestohio.com" target="_blank"><img src={CelticFest} /></a>
                        </div>
                        <div className="col-4 col-lg-3 col-xl-2 my-auto">
                            <a href="http://www.1572roadhousebarbq.com" target="_blank"><img src={BBQ} /></a>
                        </div>
                    </div>
                </div>
                <div className="section-heading">
                    <h2>
                        SPONSORS
                    </h2>
                </div>
                <div className="section-content mt-5 text-center">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-3 col-xl-2">
                            <a href="https://www.mountaindew.com/" target="_blank"><img src={MtnDew} style={{width: "100%", maxWidth: "250px"}} /></a>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-3 col-xl-2 my-3 my-lg-auto">
                            <a href="https://www.millerlite.com/" target="_blank"><img src={MillerLite} style={{maxWidth: "150px"}} /></a>
                        </div>
                        <div className="col-12 col-lg-3 col-xl-2 my-3 my-lg-auto">
                            <a href="https://www.woodchuck.com/" target="_blank"><img src={Woodchuck} style={{maxWidth: "150px"}} /></a>
                        </div>
                        <div className="col-12 col-lg-3 col-xl-2 my-3 my-lg-auto">
                            <a href="http://sonderbrewing.com/" target="_blank"><img src={Sonder} style={{maxWidth: "250px"}} /></a>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!------------------------------------------------- END SPONSORS ---------------------------------------------------}} */}

        {/* {{!---------------------------------------------------- SOCIAL ------------------------------------------------------}} */}
            <div className="section">
                <div className="section-content">
                    <div id="social-links" className="row justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8">
                            <div className="row justify-content-center">
                                {/* <!-- LARGE VERSION --> */}
                                <div className="col-4 text-center pt-5 d-none d-md-inline-block">
                                    <a href="http://www.facebook.com/brimstonehaunt" target="_blank">
                                        <span className="fa fa-icon-huge fa-facebook-f icon-zoom icon-circle">
                                            <FontAwesomeIcon icon="fa-brands fa-facebook-f" className="fa fa-icon-huge my-auto"></FontAwesomeIcon>
                                        </span>
                                        <br/><br/><span className="prompt">LIKE US</span>
                                    </a>
                                </div>
                                <div className="col-4 text-center pt-5 d-none d-md-inline-block">
                                    <a href="http://www.instagram.com/brimstonehaunt" target="_blank">
                                        <span className="fa fa-icon-huge fa-instagram icon-zoom icon-circle">
                                            <FontAwesomeIcon icon="fa-brands fa-instagram" className="fa fa-icon-huge my-auto"></FontAwesomeIcon>
                                        </span>
                                        <br/><br/><span className="prompt">FOLLOW US</span>
                                    </a>
                                </div>
                                {/* <!-- SMALL VERSION --> */}
                                <div className="col-4 text-center pt-5 d-inline-block d-md-none">
                                    <a href="http://www.facebook.com/brimstonehaunt" target="_blank">
                                        <span className="fa fa-icon-normal fa-facebook-f icon-zoom icon-circle">
                                            <FontAwesomeIcon icon="fa-brands fa-facebook-f" className="fa fa-icon-normal my-auto"></FontAwesomeIcon>
                                        </span>
                                    </a>
                                </div>
                                <div className="col-4 text-center pt-5 d-inline-block d-md-none">
                                    <a href="http://www.instagram.com/brimstonehaunt" target="_blank">
                                        <span className="fa fa-icon-normal fa-instagram icon-zoom icon-circle">
                                            <FontAwesomeIcon icon="fa-brands fa-instagram" className="fa fa-icon-normal my-auto"></FontAwesomeIcon>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!-------------------------------------------------- END SOCIAL ----------------------------------------------------}} */}
            <div id="footer-links" className="row justify-content-center text-center py-3">
                <div className="col-12 col-sm-4 col-lg-3 col-xl-1">
                    <a href="/policies" className="subtle-link">Policies</a>
                </div>
                <div className="col-12 col-sm-4 col-lg-4 col-xl-2 border-left border-right border-white">
                    <a href="/news" className="subtle-link">Press Releases</a>
                </div>
                <div className="col-12 col-sm-4 col-lg-3 col-xl-1">
                    <a href="/employees" target="_blank" className="subtle-link">Employees</a>
                </div>
            </div>
        </Container>
    </>);
}