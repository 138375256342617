import Logo from "./img/logos/brimstone-haunt-logo_textured-2020.png";
import NowHiring from "./img/now-hiring.png";

import * as React from 'react';
import { Outlet, Link, useLocation, useMatch } from 'react-router-dom';
import {
    Navbar, Nav, NavDropdown,
    Container, Row, Col
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Home from './home';
import Xmas from './home_xmas';
import Apply from './apply';
import Policies from './policies';
import News from './news';

function Header() {
    return (
        <Navbar collapseOnSelect  variant="dark" expand="md" id="main-nav" className="fixed-top py-0 px-3">
            <Navbar.Brand href="/"><img alt ="Logo" src={Logo} /></Navbar.Brand>

            <Navbar.Toggle aria-controls="main-nav-menu" />

            <Navbar.Collapse id="main-nav-menu" className="flex-md-column">
                <Nav id="navbar-social-links" className="ms-auto justify-content-center">
                    <li className="nav-item ms-2">
                        <a href="http://www.facebook.com/brimstonehaunt" target="_blank">
                            <FontAwesomeIcon icon="fa-brands fa-facebook-f" className="fa fa-icon-small bg-transparent"></FontAwesomeIcon>
                        </a>
                    </li>
                    <li className="nav-item ms-2">
                        <a href="http://www.instagram.com/brimstonehaunt" target="_blank">
                            <FontAwesomeIcon icon="fa-brands fa-instagram" className="fa fa-icon-small bg-transparent"></FontAwesomeIcon>
                        </a>
                    </li>
                </Nav>
                <Nav className="ms-auto my-5 my-md-auto">
                    <li className="nav-item"><a className="nav-link py-md-0 text-md-right" href="/apply" title="Fill Out an Application"><img src={NowHiring} style={{"maxHeight": "40px"}} /></a></li>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

function Footer() {
    return (
        <footer className="footer">
            <Container fluid>
                <Row className="justify-content-center">
                    <Col xs="auto" className="text-center">
                        <span>&copy;{new Date().getFullYear()} Brimstone Haunt LLC | <a href="mailto:contact@BrimstoneHaunt.com" className="subtle-link">contact@BrimstoneHaunt.com</a></span>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

const Brimstone = {
    Header,
    Footer,
    Home,
    Xmas,
    Apply,
    Policies,
    News
};

export default Brimstone;