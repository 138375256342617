import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Container, Row, Col, Button, Form, FloatingLabel
} from 'react-bootstrap';

export default function News(props) {
    React.useEffect(function() {
        
    }, []);

    return (<>
        <Container fluid id="news-page" className="py-5">
            <Row className="justify-content-center my-5">
                <Col xs={11} xl={9} xxl={7} className="rounded-3 border shadow-dark bg-white text-black">
                    <div className="row my-5">
                        <div className="col">
                            <h1>Press Releases</h1>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-5">
                        <div className="col">
                            <ul className="list-group">
                                {/* {{!-- Header --}} */}
                                <li className="list-group-item list-group-item-dark">
                                    <div className="row">
                                        <div className="col-6">
                                            Headline
                                        </div>
                                        <div className="col-3">
                                            Date
                                        </div>
                                        <div className="col-3">
                                            Download
                                        </div>
                                    </div>
                                </li>
                                {/* {{!-- 2024 --}} */}
                                <li className="list-group-item">
                                    <div className="row">
                                        <div className="col-6">
                                            Brimstone Haunt Opens Friday The 13!
                                        </div>
                                        <div className="col-3">
                                            September 2024
                                        </div>
                                        <div className="col-3">
                                            <a target='_blank' href="/media/pdf/media_release_2024.pdf">Download</a>
                                        </div>
                                    </div>
                                </li>
                                {/* {{!-- 2023 --}} */}
                                <li className="list-group-item">
                                    <div className="row">
                                        <div className="col-6">
                                            Brimstone Haunt Unleashes a Slew of Thrilling Attractions for the 2023 Halloween Season
                                        </div>
                                        <div className="col-3">
                                            September 2023
                                        </div>
                                        <div className="col-3">
                                            <a target='_blank' href="/media/pdf/media_release_2023.pdf">Download</a>
                                        </div>
                                    </div>
                                </li>
                                {/* {{!-- 2022 --}} */}
                                <li className="list-group-item">
                                    <div className="row">
                                        <div className="col-6">
                                            Meet the frightful residents of Brimstone Haunt
                                        </div>
                                        <div className="col-3">
                                            September 2022
                                        </div>
                                        <div className="col-3">
                                            <a target='_blank' href="/media/pdf/media_release_2022.pdf">Download</a>
                                        </div>
                                    </div>
                                </li>
                                {/* {{!-- 2020 --}} */}
                                <li className="list-group-item">
                                    <div className="row">
                                        <div className="col-6">
                                            Brimstone Haunt Opens September 25 for Scarily Safe Zone
                                        </div>
                                        <div className="col-3">
                                            September 2020
                                        </div>
                                        <div className="col-3">
                                            <a target='_blank' href="/media/pdf/media_release_2020.pdf">Download</a>
                                        </div>
                                    </div>
                                </li>
                                {/* {{!-- 2019 --}} */}
                                <li className="list-group-item">
                                    <div className="row">
                                        <div className="col-6">
                                            Brimstone Haunt Opens September 27
                                        </div>
                                        <div className="col-3">
                                            September 2019
                                        </div>
                                        <div className="col-3">
                                            <a target='_blank' href="/media/pdf/media_release_2019.pdf">Download</a>
                                        </div>
                                    </div>
                                </li>
                                {/* {{!-- 2018 --}} */}
                                <li className="list-group-item">
                                    <div className="row">
                                        <div className="col-6">
                                            Brimstone Haunt Press Release 2018
                                        </div>
                                        <div className="col-3">
                                            August 2018
                                        </div>
                                        <div className="col-3">
                                            <a target='_blank' href="/media/pdf/media_release_2018.pdf">Download</a>
                                        </div>
                                    </div>
                                </li>
                                {/* {{!-- 2017 --}} */}
                                <li className="list-group-item">
                                    <div className="row">
                                        <div className="col-6">
                                            New Haunted Attractions Opening September 22 At Renaissance Park Event Center
                                        </div>
                                        <div className="col-3">
                                            August 2017
                                        </div>
                                        <div className="col-3">
                                            <a target='_blank' href="/media/pdf/media_release_08242017.pdf">Download</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </>)
}